<template>
    <div class="container">
        <el-container>
            <el-header height="48px">
                <el-form
                    :inline="true"
                    :model="filterOption"
                    size="mini"
                >
                    <el-row>
                        <el-form-item>
                            <el-input
                                v-model="filterOption.countryCode"
                                :placeholder="$t('addressbook.countryCode')"
                            ></el-input>
                        </el-form-item>

                        <el-form-item>
                            <el-input
                                v-model="filterOption.countryName"
                                :placeholder="$t('addressbook.countryName')"
                            ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input
                                v-model="filterOption.country"
                                :placeholder="$t('addressbook.country')"
                            ></el-input>
                        </el-form-item>

                    </el-row>

                </el-form>
                <div class="rightBtn">
                    <el-button type="primary" size="mini">{{$t('system.search')}}</el-button>
                    <el-button type="warning" size="mini">{{$t('system.reset')}}</el-button>
                </div>
            </el-header>
            <el-main>
                <div class="tableHeader">
                    <el-button size="mini">{{$t('system.import')}}</el-button>
                    <el-button
                        size="mini"
                        @click="showCreateDialog"
                    >{{$t('system.create')}}</el-button>
                    <el-button size="mini">{{$t('system.delete')}}</el-button>
                </div>
                <el-table
                    :data="tableData"
                    ref="accountTable"
                    border
                    :height="tHeight"
                    v-loading="loading"
                    @selection-change="onTableSelectionChange"
                    tooltip-effect="dark"
                    style="width: 100%"
                >
                    <el-table-column
                        type="selection"
                        width="40"
                        fixed
                    ></el-table-column>
                    <el-table-column
                        type="index"
                        :label="$t('system.index')"
                        width="60"
                        align="center"
                        fixed
                    ></el-table-column>
                    <el-table-column
                        prop="countryCode"
                        :label="$t('addressbook.countryCode')"
                        align="center"
                        show-overflow-tooltip
                    ></el-table-column>
                    <el-table-column
                        prop="countryName"
                        :label="$t('addressbook.countryName')"
                        align="center"
                        show-overflow-tooltip
                    ></el-table-column>
                    <el-table-column
                        prop="country"
                        :label="$t('addressbook.country')"
                        align="center"
                        show-overflow-tooltip
                    ></el-table-column>
                    <el-table-column
                        prop="remark"
                        :label="$t('addressbook.remark')"
                        align="center"
                        show-overflow-tooltip
                    ></el-table-column>
                    <el-table-column
                        :label="$t('system.operation')"
                        fixed="right"
                        align="center"
                        width="150"
                    >
                        <template v-slot="scope">
                            <el-link
                                type="primary"
                                icon="el-icon-edit"
                                @click="edit(scope.row)"
                            >{{$t('system.edit')}}</el-link>
                            <el-link
                                type="warning"
                                icon="el-icon-delete"
                                @click="del(scope.row)"
                                style="margin-left:20px;"
                            >{{$t('system.delete')}}</el-link>

                        </template>
                    </el-table-column>
                </el-table>
                <div style="float:right;margin-top:10px">
                    <el-pagination
                        @size-change="onTableSizeChange"
                        @current-change="onTablePageChange"
                        :current-page="filterOption.pageNo"
                        :page-sizes="[10, 50, 100, 200]"
                        layout="total,sizes,prev, pager, next"
                        :total="filterOption.total"
                    ></el-pagination>
                </div>

            </el-main>

        </el-container>

    </div>
</template>


<script>
import mixin from "@/utils/mixin.js";
export default {
  name:"country",
  mixins: [mixin],
  data() {
    return {
      loading:false,
      isShowCreateDialog: false,
      filterOption: {
        countryCode: null,
        countryName: null,
        country: null,
        pageNo: 1,
        pageSize: 10,
        total: 0
      },

      tableData: [
        {
          countryCode: "CN",
          countryName:"中国",
          country:"China",
          remark: "中华人民共和国"
        },
      {
          countryCode: "US",
          countryName:"美国",
          country:"America",
          remark: "美利坚合众国"
        },
      {
          countryCode: "UK",
          countryName:"英国",
          country:"England",
          remark: "大英帝国"
        },
      {
          countryCode: "JP",
          countryName:"日本",
          country:"Japan",
          remark: "小日本帝国"
        },
      {
          countryCode: "KR",
          countryName:"韩国",
          country:"Korea",
          remark: "欧巴欧巴"
        },
      
      ],
      tableSelection:[]
    };
  },
  computed: {
    tHeight() {
      return this.tableHeight - 150;
    }
  },
  mounted() {

  },
  methods: {
    edit(row) {
      console.log(row);
    },
    del(row) {
      console.log(row);
    },
    onTableSizeChange(size) {
      console.log(size);
    },
    onTablePageChange(page) {
      console.log(page);
    },
    showCreateDialog() {
      this.isShowCreateDialog = true;
    },
    closeCreateDialog() {
      this.isShowCreateDialog = false;
    },
        onTableSelectionChange(val) {
      this.tableSelection = val;
    },
  }
};
</script>


<style lang="less" scoped>
.container {
  height: calc(100% - 20px);
  background-color: white;
  overflow-y: scroll;
  padding: 10px;

  .el-header {
    display: flex;
    justify-content: space-between;
   align-items: flex-start;

    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 10px;
    :deep(el-form-item) {
      margin-right: 10px;
    }
  }

  :deep(.el-main) {
    padding: 0 20px;
    .tableHeader {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 48px;
    }
  }
}
</style>
